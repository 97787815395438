import { BlockComponentMeta, BlockMeta } from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../../../components/Text/constants/globalTextCssVariables'
import { containerComponentFormRegisterValuesFactory } from '../../../components/Container/form/ContainerComponentForm'
import { randomString } from '@sceneio/tools'
import { cardDefaults } from '../../../components/Card/cardMeta'
import type {
  BlockComponent,
  CardComponent,
  FeatureGrid2Block,
} from '@sceneio/schemas/lib/types'
import { mediaDefaults } from '../../../components/Media/mediaMeta'
import { blockContainerDefaults } from '../../../components/BlockContainer/blockContainerMeta'
import { DEFAULT_OUTLINE_BUTTON_CSS_VARIABLES } from '../../../components/Button/constants/globalButtonCssVariables'
import { textToLexical } from '@sceneio/wysiwyg-utils'
import {
  blockLayoutAdditionalFormFieldRenderer,
  buttonContainerLayoutAdditionalFormFieldRenderer,
} from '../../Hero/SplitHero/form/additionalFormFieldRenderers'

const { border, ...CARD_DEFAULTS } = cardDefaults.config
const MEDIA_DEFAULTS = mediaDefaults.config
const BLOCK_CONTAINER_DEFAULTS = blockContainerDefaults.config

export const buttonSampleFactory = (data = {}) => ({
  id: randomString(8),
  children: textToLexical('Button', 'array-item'),
  width: {
    data: {
      '--width-default': 'auto',
      '--width-xs': '100%',
    },
  },
  ...data,
})

export const cardItemSampleFactory = (): CardComponent => ({
  id: randomString(),
  ...CARD_DEFAULTS,
  padding: {
    data: {
      '--padding-default-top': '1rem',
      '--padding-default-bottom': '1rem',
    },
  },
  components: {
    ...CARD_DEFAULTS.components,
    media: {
      format: 'image',
      components: {
        ...CARD_DEFAULTS.components.media.components,
        image: {
          responsiveConfig: {
            default: {
              src: 'https://assets.scene.io/global/placeholders/check-circle.svg',
            },
          },
        },
      },
    },
    tagline: {
      ...CARD_DEFAULTS.components.tagline,
      show: false,
    },
    heading: {
      ...CARD_DEFAULTS.components.heading,
      as: 'h4',
      ...DEFAULT_HEADINGS_CSS_VARIABLES['h4'],
      children: textToLexical('Short heading here', 'h4'),
    },
    buttons: {
      ...CARD_DEFAULTS.components.buttons,
      show: false,
    },
    contentContainer: {
      ...CARD_DEFAULTS.components.contentContainer,
      padding: {
        data: {
          '--padding-default-left': 0,
          '--padding-default-right': 0,
          '--padding-default-top': '2rem',
        },
      },
    },
  },
})

export const featureGrid2ComponentMeta: BlockComponentMeta<FeatureGrid2Block> =
  {
    block: {
      path: 'components.block',
      name: 'Block',
      type: 'block',
      defaultConfig: {
        swapOrder: false,
        columnRatio: 7,
      } as BlockComponent & {
        swapOrder?: boolean
        columnRatio: number
      },
      configEditor: {
        additionalFormFields: {
          layout: {
            formFieldRenderer: blockLayoutAdditionalFormFieldRenderer,
          },
        },
      },
    },
    blockContainer: {
      path: 'components.block.blockContainer',
      name: 'Container',
      disableToggleShow: true,
      defaultConfig: {
        responsiveConfig: {
          default: {
            maxWidth: 'screen',
            rowGap: '1.5rem',
            columnGap: '2.5rem',
          },
        },
        verticalAlignment: {
          data: {
            '--vertical-alignment-default': 'center',
          },
        },
        padding: BLOCK_CONTAINER_DEFAULTS.padding,
      },
      type: 'blockContainer',
    },
    tagline: {
      path: 'components.tagline',
      name: 'Tagline',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.tagline',
        },
      ],
      defaultConfig: {
        children: textToLexical('Tagline'),
        as: 'p',
        margin: {
          data: {
            '--margin-default-bottom': '1.5rem',
          },
        },
        font: {
          data: {
            default: {
              '--font-default-weight-default': 'bold',
            },
          },
        },
      },
      type: 'text',
    },
    heading: {
      path: 'components.heading',
      name: 'Heading',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.heading',
        },
      ],
      defaultConfig: {
        ...DEFAULT_HEADINGS_CSS_VARIABLES['h2'],
        children: textToLexical(
          'Medium length section heading goes here',
          'h2',
        ),
        as: 'h2',
        margin: {
          data: {
            '--margin-default-bottom': '1.5rem',
          },
        },
      },
      type: 'text',
    },
    text: {
      path: 'components.text',
      name: 'Text',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.text',
          editorOptions: {
            include: ['table', 'list'],
          },
        },
      ],
      defaultConfig: {
        children: textToLexical(
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
        ),
        as: 'p',
        margin: {
          data: {
            '--margin-default-bottom': '2rem',
          },
        },
      },
      type: 'text',
    },
    cards: {
      path: 'components.cards',
      name: 'Cards',
      type: 'grid',
      disableToggleShow: true,
      components: {
        items: {
          isArrayComponent: true,
          sampleFactory: cardItemSampleFactory,
          path: 'items',
          name: 'Card',
          type: 'card',
          disableToggleShow: true,
          components: {
            media: {
              type: 'media',
              name: 'Media',
              path: 'components.media',
              components: {
                credits: {
                  path: 'components.credits',
                  inlineEditors: [
                    {
                      editorType: 'inlineText',
                      editorPath: 'components.media.components.credits',
                      editorOptions: {
                        isNestedArrayItem: true,
                      },
                    },
                  ],
                  disableEditing: true,
                  name: 'Credits',
                  type: 'text',
                },
              },
            },
            contentContainer: {
              type: 'container',
              name: 'Content container',
              path: 'components.contentContainer',
              configEditor: {
                formProps: {
                  name: 'group',
                  registerValues:
                    containerComponentFormRegisterValuesFactory('group'),
                },
              },
            },
            tagline: {
              type: 'text',
              name: 'Tagline',
              path: 'components.tagline',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.tagline',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            heading: {
              type: 'text',
              name: 'Heading',
              path: 'components.heading',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.heading',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            description: {
              type: 'text',
              name: 'Description',
              path: 'components.description',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.description',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            buttons: {
              type: 'container',
              name: 'Buttons',
              path: 'components.buttons',
              components: {
                items: {
                  isArrayComponent: true,
                  path: 'items',
                  name: 'Button',
                  type: 'button',
                  disableToggleShow: true,
                  sampleFactory: () => buttonSampleFactory(),
                },
              },
              inlineEditors: [
                {
                  editorType: 'buttonEditor',
                  editorPath: 'components.buttons.items',
                  editorOptions: {
                    isNestedArrayItem: true,
                    isItemArray: true,
                    sampleFactory: () => buttonSampleFactory(),
                  },
                },
              ],
              configEditor: {
                additionalFormFields: {
                  layout: {
                    formFieldRenderer:
                      buttonContainerLayoutAdditionalFormFieldRenderer,
                  },
                },
                formProps: {
                  name: 'componentArray',
                  registerValues:
                    containerComponentFormRegisterValuesFactory(
                      'componentArray',
                    ),
                },
              },
            },
          },
        },
      },
      defaultConfig: {
        items: [cardItemSampleFactory(), cardItemSampleFactory()],
        gap: {
          data: {
            '--column-gap-default': '1.5rem',
          },
        },
        columns: {
          data: {
            '--columns-default': 2,
            '--columns-md': 2,
            '--columns-xs': 1,
          },
        },
      },
    },
    buttons: {
      path: 'components.buttons',
      components: {
        items: {
          isArrayComponent: true,
          path: 'items',
          name: 'Button',
          type: 'button',
          disableToggleShow: true,
          sampleFactory: () => buttonSampleFactory(),
        },
      },
      inlineEditors: [
        {
          editorType: 'buttonEditor',
          editorPath: 'components.buttons.items',
          editorOptions: {
            isItemArray: true,
            sampleFactory: () => buttonSampleFactory(),
          },
        },
      ],
      defaultConfig: {
        items: [
          buttonSampleFactory(),
          buttonSampleFactory({
            children: textToLexical('2nd Button', 'array-item'),
            ...DEFAULT_OUTLINE_BUTTON_CSS_VARIABLES,
          }),
        ],
        show: true,
        gap: {
          data: {
            [`--row-gap-default`]: '1rem',
            [`--column-gap-default`]: '1rem',
          },
        },
        flow: {
          data: {
            '--flow-default': 'row',
            '--flow-xs': 'column',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'flex-start',
          },
        },
        verticalAlignment: {
          data: {
            '--vertical-alignment-default': 'center',
          },
        },
      },
      name: 'Buttons',
      type: 'container',
      configEditor: {
        additionalFormFields: {
          layout: {
            formFieldRenderer: buttonContainerLayoutAdditionalFormFieldRenderer,
          },
        },
        formProps: {
          name: 'componentArray',
          registerValues:
            containerComponentFormRegisterValuesFactory('componentArray'),
        },
      },
    },
    media: {
      path: 'components.media',
      name: 'Media',
      components: {
        credits: {
          path: 'components.credits',
          inlineEditors: [
            {
              editorType: 'inlineText',
              editorPath: 'components.media.components.credits',
            },
          ],
          disableEditing: true,
          name: 'Credits',
          type: 'text',
        },
      },
      defaultConfig: MEDIA_DEFAULTS,
      type: 'media',
    },
  }

export const featureGrid2BlockMeta: BlockMeta<FeatureGrid2Block> = {}

export const featureGrid2DefaultConfig =
  blockDefaultConfigFactory<FeatureGrid2Block>({
    componentMeta: featureGrid2ComponentMeta,
    blockMeta: featureGrid2BlockMeta,
    version: 1,
  })
