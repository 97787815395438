import type { SVGProps } from 'react'
import React from 'react'

const SvgSceneLogoCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_6485_16270)">
      <path
        d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.2168 5.83301C7.21671 5.83301 7.21661 5.83301 7.21652 5.83301C5.99258 5.83301 5.00038 6.76575 5.00038 7.91636C5.00038 9.06696 5.99258 9.9997 7.21652 9.9997C7.21661 9.9997 7.21671 9.9997 7.2168 9.9997L7.2168 5.83301Z"
        fill="#5E56F9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8076 14.166C12.8077 14.166 12.8077 14.166 12.8078 14.166C14.0317 14.166 15.0239 13.2333 15.0239 12.0827C15.0239 10.9321 14.0317 9.99932 12.8078 9.99932C12.8077 9.99932 12.8077 9.99932 12.8076 9.99932L12.8076 14.166Z"
        fill="#5E56F9"
      />
      <rect
        x="7.21484"
        y="5.83301"
        width="2.81562"
        height="4.16665"
        fill="#5E56F9"
      />
      <rect
        x="14.2373"
        y="5.83301"
        width="0.596107"
        height="4.16665"
        fill="#5E56F9"
      />
      <rect
        x="5.18945"
        y="10"
        width="0.596108"
        height="4.16665"
        fill="#5E56F9"
      />
      <rect
        x="12.8066"
        y="14.166"
        width="2.81562"
        height="4.16665"
        transform="rotate(-180 12.8066 14.166)"
        fill="#5E56F9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.80599 5.83496C9.80607 8.13507 11.7896 9.99966 14.2363 9.99966L14.2383 9.99966L14.2383 5.83496L9.80599 5.83496Z"
        fill="#5E56F9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2154 14.1641C10.2153 11.864 8.23185 9.99939 5.78511 9.99939L5.7832 9.99939L5.7832 14.1641L10.2154 14.1641Z"
        fill="#5E56F9"
      />
    </g>
    <path
      d="M0.5 10C0.5 4.7533 4.7533 0.5 10 0.5C15.2467 0.5 19.5 4.7533 19.5 10C19.5 15.2467 15.2467 19.5 10 19.5C4.7533 19.5 0.5 15.2467 0.5 10Z"
      stroke="#E7E8E5"
    />
    <defs>
      <clipPath id="clip0_6485_16270">
        <path
          d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
)
export default SvgSceneLogoCircle
