import { randomString } from '@sceneio/tools'
import { containerComponentFormRegisterValuesFactory } from '../../../components/Container/form/ContainerComponentForm'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../../../components/Text/constants/globalTextCssVariables'
import { BlockComponentMeta, BlockMeta } from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'
import type {
  TestimonialVariant1Block,
  TestimonialVariant1Card,
} from '@sceneio/schemas/lib/types'
import { blockContainerDefaults } from '../../../components/BlockContainer/blockContainerMeta'
import { textToLexical } from '@sceneio/wysiwyg-utils'

const BLOCK_CONTAINER_DEFAULTS = blockContainerDefaults.config

export const testimonialItemSampleFactory = (): TestimonialVariant1Card => ({
  horizontalAlignment: {
    data: {
      '--horizontal-alignment-default': 'center',
    },
  },
  id: randomString(),
  components: {
    logo: {
      show: true,
      responsiveConfig: {
        default: {
          src: 'https://assets.scene.io/global/placeholders/scene-logo-placeholder.svg',
          alt: 'Logo',
          width: 150,
        },
      },
      padding: {
        data: {
          '--padding-default-bottom': '1.5rem',
        },
      },
    },
    quote: {
      children: textToLexical(
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare.',
      ),
      as: 'p',
      font: {
        data: {
          default: {
            '--font-default-weight-default': 'bold',
          },
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'center',
        },
      },
      padding: {
        data: {
          '--padding-default-bottom': '1.5rem',
        },
      },
    },
    avatar: {
      show: true,
      responsiveConfig: {
        default: {
          src: 'https://assets.scene.io/global/placeholders/image-default-placeholder.png',
          alt: 'Avatar',
          width: 56,
        },
      },
      margin: {
        data: {
          '--margin-default-bottom': '1rem',
        },
      },
      border: {
        data: {
          default: {
            '--border-default-radius-bottom-left-default': '50px',
            '--border-default-radius-bottom-right-default': '50px',
            '--border-default-radius-top-left-default': '50px',
            '--border-default-radius-top-right-default': '50px',
          },
        },
      },
    },
    name: {
      children: textToLexical('Name Surname'),
      as: 'p',
      font: {
        data: {
          default: {
            '--font-default-weight-default': 'bold',
            '--font-default-line-height-default': '24px',
          },
        },
      },
    },
    company: {
      children: textToLexical('Position, Company name'),
      as: 'p',
      font: {
        data: {
          default: {
            '--font-default-line-height-default': '24px',
          },
        },
      },
    },
  },
})

export const testimonialVariant1ComponentMeta: BlockComponentMeta<TestimonialVariant1Block> =
  {
    block: {
      path: 'components.block',
      name: 'Block',
      type: 'block',
      defaultConfig: {},
    },
    blockContainer: {
      path: 'components.block.blockContainer',
      name: 'Container',
      disableToggleShow: true,
      defaultConfig: {
        responsiveConfig: {
          default: {
            maxWidth: 'screen',
            columnGap: '1.5rem',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        padding: BLOCK_CONTAINER_DEFAULTS.padding,
      },
      type: 'blockContainer',
    },
    heading: {
      path: 'components.heading',
      name: 'Heading',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.heading',
        },
      ],
      defaultConfig: {
        ...DEFAULT_HEADINGS_CSS_VARIABLES['h2'],
        children: textToLexical('Customer testimonials', 'h2'),
        as: 'h2',
        show: true,
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
      },
      type: 'text',
    },
    text: {
      path: 'components.text',
      name: 'Text',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.text',
          editorOptions: {
            include: ['table', 'list'],
          },
        },
      ],
      defaultConfig: {
        children: textToLexical(
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        ),
        as: 'p',
        show: true,
        padding: {
          data: {
            '--padding-default-top': '1rem',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
      },
      type: 'text',
    },
    testimonials: {
      path: 'components.testimonials',
      name: 'Testimonials',
      type: 'grid',
      disableToggleShow: true,
      components: {
        items: {
          isArrayComponent: true,
          sampleFactory: testimonialItemSampleFactory,
          path: 'items',
          name: 'Testimonial',
          type: 'container',
          disableToggleShow: true,
          configEditor: {
            formProps: {
              name: 'group',
              registerValues:
                containerComponentFormRegisterValuesFactory('group'),
            },
          },
          components: {
            logo: {
              type: 'image',
              name: 'Logo',
              path: 'components.logo',
            },
            quote: {
              type: 'text',
              name: 'Quote',
              path: 'components.quote',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.quote',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            avatar: {
              type: 'image',
              name: 'Avatar',
              path: 'components.avatar',
            },
            name: {
              type: 'text',
              name: 'Name',
              path: 'components.name',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.name',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            company: {
              type: 'text',
              name: 'Company',
              path: 'components.company',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.company',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
          },
        },
      },
      defaultConfig: {
        items: [
          testimonialItemSampleFactory(),
          testimonialItemSampleFactory(),
          testimonialItemSampleFactory(),
        ],
        margin: {
          data: {
            '--margin-default-top': '5rem',
          },
        },
        gap: {
          data: {
            [`--row-gap-default`]: '3rem',
            [`--column-gap-default`]: '1.5rem',
          },
        },
        columns: {
          data: {
            '--columns-default': 3,
            '--columns-md': 2,
            '--columns-xs': 1,
          },
        },
      },
    },
  }

export const testimonialVariant1BlockMeta: BlockMeta<TestimonialVariant1Block> =
  {}

export const testimonialVariant1DefaultConfig =
  blockDefaultConfigFactory<TestimonialVariant1Block>({
    componentMeta: testimonialVariant1ComponentMeta,
    blockMeta: testimonialVariant1BlockMeta,
    version: 1,
  })
