import { BlockComponentMeta, BlockMeta } from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../../../components/Text/constants/globalTextCssVariables'
import { containerComponentFormRegisterValuesFactory } from '../../../components/Container/form/ContainerComponentForm'
import { randomString } from '@sceneio/tools'
import type { CardComponent, FAQ3Block } from '@sceneio/schemas/lib/types'
import { blockContainerDefaults } from '../../../components/BlockContainer/blockContainerMeta'
import { cardDefaults } from '../../../components/Card/cardMeta'
import { textToLexical } from '@sceneio/wysiwyg-utils'
import { buttonContainerLayoutAdditionalFormFieldRenderer } from '../../Hero/SplitHero/form/additionalFormFieldRenderers'

const BLOCK_CONTAINER_DEFAULTS = blockContainerDefaults.config
const { border, ...CARD_DEFAULTS } = cardDefaults.config

export const cardItemSampleFactory = (): CardComponent => ({
  id: randomString(),
  ...CARD_DEFAULTS,
  components: {
    ...CARD_DEFAULTS.components,
    media: {
      format: 'image',
      components: {
        ...CARD_DEFAULTS.components.media.components,
        image: {
          responsiveConfig: {
            default: {
              src: 'https://assets.scene.io/global/placeholders/question-mark-circle.svg',
            },
          },
        },
      },
    },
    contentContainer: {
      ...CARD_DEFAULTS.components.contentContainer,
      padding: {
        data: {
          '--padding-default-left': 0,
          '--padding-default-right': 0,
          '--padding-default-top': '1.5rem',
        },
      },
    },
    tagline: {
      ...CARD_DEFAULTS.components.tagline,
      show: false,
    },
    heading: {
      children: textToLexical('Question text goes here'),
      as: 'p',
      font: {
        data: {
          default: {
            '--font-default-size-default': '18px',
            '--font-default-weight-default': 'bold',
            '--font-xs-size-default': '16px',
          },
        },
      },
      padding: {
        data: {
          '--padding-default-bottom': '1.5rem',
        },
      },
    },
    description: {
      children: textToLexical(
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.',
      ),
      as: 'p',
    },
    buttons: {
      ...CARD_DEFAULTS.components.buttons,
      show: false,
    },
  },
})

export const buttonSampleFactory = () => ({
  id: randomString(8),
  children: textToLexical('Contact us', 'array-item'),
  backgroundColor: {
    data: {
      default: {
        '--background-color-default-default': 'transparent',
      },
      hover: {
        '--background-color-default-hover': '#000',
      },
    },
  },
  border: {
    data: {
      default: {
        '--border-default-color-default': '#000',
        '--border-default-width-default': '1px',
        '--border-default-style-default': 'solid',
        '--border-default-top-style-default': 'solid',
        '--border-default-bottom-style-default': 'solid',
        '--border-default-left-style-default': 'solid',
        '--border-default-right-style-default': 'solid',
      },
    },
  },
  color: {
    data: {
      default: {
        '--color-default-default': '#000',
      },
      hover: {
        '--color-default-hover': '#fff',
      },
    },
  },
  width: {
    data: {
      '--width-default': 'auto',
      '--width-xs': '100%',
    },
  },
})

export const fAQ3ComponentMeta: BlockComponentMeta<FAQ3Block> = {
  block: {
    path: 'components.block',
    name: 'Block',
    type: 'block',
  },
  blockContainer: {
    path: 'components.block.blockContainer',
    name: 'Container',
    disableToggleShow: true,
    defaultConfig: {
      responsiveConfig: {
        default: {
          maxWidth: 'screen',
        },
      },
      padding: BLOCK_CONTAINER_DEFAULTS.padding,
    },
    type: 'blockContainer',
  },
  topHeading: {
    path: 'components.topHeading',
    name: 'Top Heading',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.topHeading',
      },
    ],
    defaultConfig: {
      ...DEFAULT_HEADINGS_CSS_VARIABLES['h2'],
      children: textToLexical('FAQs', 'h2'),
      as: 'h2',
      margin: {
        data: {
          '--margin-default-bottom': '1.5rem',
        },
      },
    },
    type: 'text',
  },
  topText: {
    path: 'components.topText',
    name: 'Top Text',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.topText',
        editorOptions: {
          include: ['table', 'list'],
        },
      },
    ],
    defaultConfig: {
      children: textToLexical(
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.',
      ),
      as: 'p',
    },
    type: 'text',
  },
  cards: {
    path: 'components.cards',
    name: 'Cards',
    type: 'grid',
    disableToggleShow: true,
    components: {
      items: {
        isArrayComponent: true,
        sampleFactory: cardItemSampleFactory,
        path: 'items',
        name: 'Card',
        type: 'card',
        disableToggleShow: true,
        components: {
          media: {
            type: 'media',
            name: 'Media',
            path: 'components.media',
            components: {
              credits: {
                path: 'components.credits',
                inlineEditors: [
                  {
                    editorType: 'inlineText',
                    editorPath: 'components.media.components.credits',
                    editorOptions: {
                      isNestedArrayItem: true,
                    },
                  },
                ],
                disableEditing: true,
                name: 'Credits',
                type: 'text',
              },
            },
          },
          contentContainer: {
            type: 'container',
            name: 'Content container',
            path: 'components.contentContainer',
            configEditor: {
              formProps: {
                name: 'group',
                registerValues:
                  containerComponentFormRegisterValuesFactory('group'),
              },
            },
          },
          tagline: {
            type: 'text',
            name: 'Tagline',
            path: 'components.tagline',
            inlineEditors: [
              {
                editorType: 'inlineText',
                editorPath: 'components.tagline',
                editorOptions: {
                  isNestedArrayItem: true,
                },
              },
            ],
          },
          heading: {
            type: 'text',
            name: 'Heading',
            path: 'components.heading',
            inlineEditors: [
              {
                editorType: 'inlineText',
                editorPath: 'components.heading',
                editorOptions: {
                  isNestedArrayItem: true,
                },
              },
            ],
          },
          description: {
            type: 'text',
            name: 'Description',
            path: 'components.description',
            inlineEditors: [
              {
                editorType: 'inlineText',
                editorPath: 'components.description',
                editorOptions: {
                  isNestedArrayItem: true,
                },
              },
            ],
          },
          buttons: {
            type: 'container',
            name: 'Buttons',
            path: 'components.buttons',
            components: {
              items: {
                isArrayComponent: true,
                path: 'items',
                name: 'Button',
                type: 'button',
                disableToggleShow: true,
                sampleFactory: () => buttonSampleFactory(),
              },
            },
            inlineEditors: [
              {
                editorType: 'buttonEditor',
                editorPath: 'components.buttons.items',
                editorOptions: {
                  isNestedArrayItem: true,
                  isItemArray: true,
                  sampleFactory: () => buttonSampleFactory(),
                },
              },
            ],
            configEditor: {
              additionalFormFields: {
                layout: {
                  formFieldRenderer:
                    buttonContainerLayoutAdditionalFormFieldRenderer,
                },
              },
            },
          },
        },
      },
    },
    defaultConfig: {
      items: [
        cardItemSampleFactory(),
        cardItemSampleFactory(),
        cardItemSampleFactory(),
        cardItemSampleFactory(),
        cardItemSampleFactory(),
        cardItemSampleFactory(),
        cardItemSampleFactory(),
        cardItemSampleFactory(),
        cardItemSampleFactory(),
      ],
      gap: {
        data: {
          [`--row-gap-default`]: '4rem',
          [`--column-gap-default`]: '4rem',
        },
      },
      columns: {
        data: {
          '--columns-default': 3,
          '--columns-md': 2,
          '--columns-xs': 1,
        },
      },
      margin: {
        data: {
          '--margin-default-top': '4rem',
          '--margin-default-bottom': '4rem',
        },
      },
    },
  },
  bottomHeading: {
    path: 'components.bottomHeading',
    name: 'Bottom Heading',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.bottomHeading',
      },
    ],
    defaultConfig: {
      ...DEFAULT_HEADINGS_CSS_VARIABLES['h2'],
      children: textToLexical('Still have a question?', 'h2'),
      as: 'h2',
      margin: {
        data: {
          '--margin-default-bottom': '1.5rem',
        },
      },
    },
    type: 'text',
  },
  bottomText: {
    path: 'components.bottomText',
    name: 'Bottom Text',
    inlineEditors: [
      {
        editorType: 'inlineText',
        editorPath: 'components.bottomText',
        editorOptions: {
          include: ['table', 'list'],
        },
      },
    ],
    defaultConfig: {
      children: textToLexical(
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      ),
      as: 'p',
      margin: {
        data: {
          '--margin-default-bottom': '1.5rem',
        },
      },
    },
    type: 'text',
  },
  buttons: {
    path: 'components.buttons',
    components: {
      items: {
        isArrayComponent: true,
        path: 'items',
        name: 'Button',
        type: 'button',
        disableToggleShow: true,
        sampleFactory: () => buttonSampleFactory(),
      },
    },
    inlineEditors: [
      {
        editorType: 'buttonEditor',
        editorPath: 'components.buttons.items',
        editorOptions: {
          isItemArray: true,
          sampleFactory: () => buttonSampleFactory(),
        },
      },
    ],
    defaultConfig: {
      items: [buttonSampleFactory()],
      show: true,
      gap: {
        data: {
          [`--row-gap-default`]: '1rem',
          [`--column-gap-default`]: '1rem',
        },
      },
      flow: {
        data: {
          '--flow-default': 'row',
          '--flow-xs': 'column',
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'flex-start',
        },
      },
      verticalAlignment: {
        data: {
          '--vertical-alignment-default': 'center',
        },
      },
    },
    name: 'Buttons',
    type: 'container',
    configEditor: {
      additionalFormFields: {
        layout: {
          formFieldRenderer: buttonContainerLayoutAdditionalFormFieldRenderer,
        },
      },
      formProps: {
        name: 'componentArray',
        registerValues:
          containerComponentFormRegisterValuesFactory('componentArray'),
      },
    },
  },
}

export const fAQ3BlockMeta: BlockMeta<FAQ3Block> = {}

export const fAQ3DefaultConfig = blockDefaultConfigFactory<FAQ3Block>({
  componentMeta: fAQ3ComponentMeta,
  blockMeta: fAQ3BlockMeta,
  version: 1,
})
